import "./LoadingOverlay.scss";

export default function LoadingOverlay({ visible = true }: { visible?: boolean }) {
  if (!visible) return null;

  return (
    <div className="loading-overlay rounded-3">
      <div className="spinner-border text-primary">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
