import { BaseResponseType } from "services/shared-models/sharedTypes";
import { HttpClient } from "helpers";
import {
  CountryCodeResultResponse,
  CountryResultResponse,
  DegreeResultResponse,
  GenderResultResponse,
  JobResultResponse,
} from "./models/LookupsType";

export const LookupService = {
  getCountryCodes: async () => {
    return HttpClient.get<BaseResponseType<CountryCodeResultResponse[]>>("/api/lookup/GetAllCountryCodes");
  },
  getGenders: async () => {
    return HttpClient.get<BaseResponseType<GenderResultResponse[]>>("/api/lookup/GetAllGenders");
  },
  getDegrees: async () => {
    return HttpClient.get<BaseResponseType<DegreeResultResponse[]>>("/api/lookup/GetAllDegrees");
  },
  getJobs: async () => {
    return HttpClient.get<BaseResponseType<JobResultResponse[]>>("/api/lookup/GetAllJobs");
  },
  getNationalities: async () => {
    return HttpClient.get<BaseResponseType<CountryResultResponse[]>>("/api/lookup/GetAllCountries");
  },
};
