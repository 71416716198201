import { HttpClient } from "helpers";
import {
  LoginRequestType,
  LoginResponseType,
  RefereeSignUpRequestType,
  ResetPasswordRequestType,
  ResetPasswordResponseType,
  SignupResponseType,
} from "./models/AccountType";
import { BaseResponseType } from "services/shared-models/sharedTypes";

export const AccountService = {
  login: async (data: LoginRequestType) => {
    return await HttpClient.post<BaseResponseType<LoginResponseType>>("/api/Account", data);
  },
  signUp: async (data: RefereeSignUpRequestType) => {
    return await HttpClient.post<BaseResponseType<SignupResponseType>>("/api/Account/signup", data);
  },
  setPassword: async (data: ResetPasswordRequestType) => {
    return await HttpClient.post<BaseResponseType<ResetPasswordResponseType>>("/api/Account/SetPassword", data);
  },
};
