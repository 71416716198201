import { Fragment } from "react";
import { useMenuContext } from "../../../contexts/menu/menuContext";
import { HomeRoutes } from "../../../routes/homeRoutes/homeRoutes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RouteItemConfig } from "../../../routes/models";

function SideMenu() {
  const { isMenuOpen, toggleMenu, setSelectedMenuItem, selectedMenuItem } = useMenuContext();
  const { t } = useTranslation("Shared");

  const homeRoutes = HomeRoutes;
  const groupList = (): any[] => {
    let groupsName: any[] = [];
    homeRoutes.forEach((route) => {
      if (groupsName.indexOf(route.groupName) === -1) {
        groupsName.push(route.groupName);
      }
    });
    return groupsName;
  };

  const openSubMenu = (route: RouteItemConfig) => {
    const subMenu = document.getElementById(`${route.path}-menu`);
    const toggle = document.getElementById(`${route.path}`);
    if (subMenu) {
      if (subMenu.classList.contains("d-block")) {
        subMenu.classList.remove("d-block");
      } else {
        if (!isMenuOpen) toggleMenu();
        subMenu.classList.add("d-block");
      }
    }
    if (toggle) {
      toggle.classList.toggle("active");
    }
  };

  const isActivated = (route: RouteItemConfig) => {
    return selectedMenuItem?.path === route.path || route.path === window.location.pathname ? "active" : "";
  };

  return (
    <div id="side-menu" className={`side-menu i h-100 rounded-4 overflow-auto ${isMenuOpen ? "" : "collapsed"}`}>
      {groupList().map((group: any, index) => {
        return (
          <div key={`group-${group}-${index}`} className="side-menu-section">
            {group && <p className="text-darkGray fs-6">{t(group)}</p>}
            {homeRoutes
              .filter((route) => route.groupName === group && route.showInMenu)
              .map((route) => {
                if (route.subMenu && route.subMenu.filter((subRoute) => subRoute.showInMenu).length > 0) {
                  return (
                    <Fragment key={`${route.path}`}>
                      <div
                        onClick={() => openSubMenu(route)}
                        className="d-flex gap-2 align-items-center my-2 text-gray border-0 menu-item btn p-13-16P cursor-pointer"
                      >
                        <div>
                          <i className="icon-workflow fs-32P"></i>
                        </div>
                        <div className="menu-item-text">{t(route.name)}</div>
                        <i id={route.path} className="side-dropdown-toggle" />
                      </div>
                      <ul id={`${route.path}-menu`} className="submenu">
                        {route.subMenu.map((subRoute, index) => {
                          return (
                            <li key={`${subRoute.path}-${index}`}>
                              <Link
                                to={subRoute.path}
                                onClick={() => setSelectedMenuItem(subRoute)}
                                className={`btn p-13-16P ${
                                  isActivated(subRoute) ? "text-primary bg-primary bg-opacity-10" : "text-gray"
                                } `}
                              >
                                {t(subRoute.name)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Fragment>
                  );
                }

                return (
                  <Link
                    to={route.path}
                    key={route.path}
                    onClick={() => setSelectedMenuItem(route)}
                    className={`d-flex gap-2 align-items-center my-2 ${
                      isActivated(route) ? "text-primary bg-primary bg-opacity-10" : "text-gray"
                    } menu-item btn p-13-16P ${selectedMenuItem?.path === route.path ? "active" : ""}`}
                  >
                    <div>
                      <i className={`${route.icon} fs-32P`}></i>
                    </div>
                    <div className="menu-item-text">{t(route.name)}</div>
                  </Link>
                );
              })}
          </div>
        );
      })}
      <div id="menu-button" className="position-absolute menu-button" onClick={toggleMenu}></div>
    </div>
  );
}

export default SideMenu;
