import { useTranslation } from "react-i18next";
import ResearchLogo from "assets/image/login/resarchlogo.png";
import Icon from "assets/image/login/icon.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AccountService } from "services/accountService";
import { useForm } from "react-hook-form";
import { ResetPasswordFormType, getSetPasswordSchemaSchema } from "./set-password-schema";
import FormInput from "components/blocks/FormInput";

const SetPasswordView = () => {
  const { t } = useTranslation(["SetPasswordView", "Common"]);
  const navigate = useNavigate();

  //! get token from the param
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const [isFormValidated, setIsFormValidated] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ResetPasswordFormType>({
    values: {
      token: token ?? "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: getSetPasswordSchemaSchema(t),
  });
  const [formSubmissionStates, setFormSubmissionStates] = useState({
    isLoading: false,
    error: "",
  });

  const onFormSubmit = async (data: ResetPasswordFormType) => {
    setIsFormValidated(true);

    try {
      setFormSubmissionStates({ ...formSubmissionStates, isLoading: true });
      const res = await AccountService.setPassword(data);
      if (!res.hasError) {
        navigate("/account/login");
      } else {
        setFormSubmissionStates({
          error: t(res.description),
          isLoading: false,
        });
      }
    } catch (error) {
      setFormSubmissionStates({
        ...formSubmissionStates,
        error: t("Common:internalServerError"),
        isLoading: false,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row rest-password-container p-2">
        <div className="col-md-6  col-sm-12  rest-password-image ">
          <div className="overlay p-4  ">
            <div>
              <img src={ResearchLogo} width="181" height="88" alt="Logo" className="mb-3" />
            </div>

            <div>
              <h1>{t("universityName")}</h1>
              <p>{t("universityDescription")}</p>
            </div>
          </div>
        </div>

        <div className="col-md-6  col-sm-12  d-flex justify-content-center h-100 ">
          <div className="rest-password-form  rounded">
            <div className=" text-center mb-4">
              <img src={Icon} width="92" height="147" alt="Logo" className="mb-3" />
            </div>
            <div>
              <div className=" d-flex flex-column align-items-center">
                <h2> {t("welcome")}</h2>
                <p className="text-darkGray">{t("restPasswordDescription")}</p>
              </div>
              <form noValidate className={isFormValidated ? "was-validated" : ""} onSubmit={handleSubmit(onFormSubmit)}>
                <div className="form-group my-2">
                  <FormInput
                    type="email"
                    label={t("email")}
                    inputClassName="form-control"
                    placeholder={t("email")}
                    error={errors.email?.message}
                    {...register("email")}
                  />
                </div>
                <div className="form-group my-2">
                  <FormInput
                    type="password"
                    label={t("password")}
                    inputClassName="form-control"
                    placeholder={t("password")}
                    error={errors.password?.message}
                    {...register("password")}
                  />
                </div>

                <div className="form-group my-2">
                  <FormInput
                    type="password"
                    label={t("confirmPassword")}
                    inputClassName="form-control"
                    placeholder={t("confirmPassword")}
                    error={errors.confirmPassword?.message}
                    {...register("confirmPassword")}
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-dark btn-block w-100 my-3"
                  disabled={formSubmissionStates.isLoading}
                >
                  {formSubmissionStates.isLoading ? <span className="custom-loader"></span> : t("restPasswordButton")}
                </button>
                <div className="text-center text-danger">{formSubmissionStates.error}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordView;
