import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ProgramLocalResult } from "services/programs/models/ProgramTypes";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ProgramsService } from "services/programs";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { toLocalCalender } from "helpers";

export default function ProgramsGridView() {
  const { t } = useTranslation("Programs");
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [programs, setPrograms] = useState<ProgramLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      // TODO: get request object from the store (all props (sort, filter, pagination))
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
    })),
  );

  const fetchPrograms = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ProgramsService.getAllProgramsAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage, // TODO: should I start from 0?
        pageSize: 10,
        search: searchValue,
      });

      setPrograms(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pagination.currentPage === 1) return;
    fetchPrograms();
  }, [pagination.currentPage]);

  useEffect(() => {
    fetchPrograms({ resetPagination: true });
  }, [sortState, searchValue /* TODO: model filter */]);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "programDetail.displayName",
        displayName: t("programName"),
        sortable: true,
        render: (row: ProgramLocalResult) => {
          return <Link to={`${row.id}`}>{row.programDetail.displayName}</Link>;
        },
      },
      {
        // TODO: ask backend to return "{type} - {classification}"
        accessor: "programType",
        field: "ProgramType",
        displayName: t("programTypeClass"),
        sortable: true,
        filterable: true,
      },
      {
        field: "adsEndDate",
        displayName: t("adsClose"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render: (row: ProgramLocalResult) => {
          return <>{toLocalCalender(new Date(row.adsEndDate).toLocaleDateString())}</>;
        },
      },
      {
        accessor: "programOrders",
        field: "ProgramOrders",
        displayName: t("programOrders"),
        sortable: true,
      },
      {
        accessor: "fundingDurationInMonths",
        field: "FundingDurationInMonths",
        displayName: t("fundingDuration"),
        sortable: true,
      },
      {
        field: "Status",
        displayName: t("status"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "1", value: t("active") },
            { id: "0", value: t("inactive") },
          ],
        },
        render(row: ProgramLocalResult) {
          // TODO: color based on status (primary, success, danger)
          const statusClassName = row.status === "Completed" ? "bg-success" : "bg-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(row.status)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        sortable: false,
        render: (row: ProgramLocalResult) => {
          return (
            <Link to={`/home/allPrograms/${row.id}`} className="btn text-darkGray btn-sm">
              <i className="icon-edit" />
            </Link>
          );
        },
      },
    ],
    [],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allPrograms"),
    singularName: t("program"),
    addPageRoute: "/home/programs/add",
    totalCount: totalCount,
  };

  //! -> for testing purposes only
  // TODO: remove these from here
  useEffect(() => {
    console.log({ sortState, pagination });
  }, [sortState, pagination]);
  // TODO: to here

  return (
    <>
      {/* <button onClick={() => fetchPrograms()}>Fetch Programs</button> */}
      <GridView
        loading={loading}
        gridProps={{
          data: programs ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
    </>
  );
}

/*
{
  "data": {
      "result": [],
      "totalCount": 0
  },
  "code": 0,
  "hasError": false,
  "description": null
}
*/
