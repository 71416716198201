import { useEffect, useState } from "react";
import { useCookieContext } from "../../../contexts";
import { TranslationModel } from "../../../helpers";
import { LoginResult } from "../../../services/users/models/loginResult";
import manImage from "../../../assets/image/Header/man.png";
import icon from "../../../assets/image/login/icon.png";

function Header() {
  const { userSession, localization } = useCookieContext();
  const [user, setUser] = useState<LoginResult>();
  const [locale, setLocale] = useState<TranslationModel>();
  useEffect(() => {
    if (localization) {
      setLocale(JSON.parse(localization));
    }
    if (userSession) {
      setUser(JSON.parse(userSession));
    }
  }, [userSession, localization]);
  return (
    <>
      <div className="header gap-4">
        <div className="right-section gap-4 w-100">
          <a className="home-link">
            <img src={icon} alt="Home Icon" className="home-icon" />
          </a>

          <div className="text-white">
            <span>Breadcrumb</span>
          </div>
        </div>
        <div className="center-section">
          <button className="message-btn">
            <i className={"icon-search-24 text-white fs-24px"} />
          </button>

          <button className="message-btn">
            {/*<img src="~/image/Icons/messages.svg" width="24" height="24" alt="messages"/>*/}
            <i className={"icon-messages text-white fs-24px"} />
          </button>
          <button className="message-btn">
            <i className={"icon-bell text-white fs-24px"} />
          </button>
        </div>
        <div className="left-section">
          <div className="profile-link ">
            <div className="d-flex gap-2  align-items-center">
              <div>
                <img src={manImage} alt="Profile" className="profile-pic" />
              </div>
              <div className="profile-info">
                {locale?.CurrentLanguage === "ar" ? (
                  <span className="profile-name">{user?.ArabicName}</span>
                ) : (
                  <span className="profile-name">{user?.EnglishName}</span>
                )}
                <span className="profile-role">المدير المسؤول</span>
              </div>
            </div>
            <div>
              <i className={"icon-arrow-down"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
