const TextBoxFilter: React.FC<any> = (props) => {
  return (
    <div className="form-group m-2">
      <label className="my-1" asp-for={props.field}>
        {props.displayName}
      </label>
      <input
        id={props.field}
        type="text"
        className="form-control"
        value={props.value}
        placeholder={props.displayName}
        aria-label={props.displayName}
      />
    </div>
  );
};

export default TextBoxFilter;
