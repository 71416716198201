import { iconClassNames } from "assets/style/theme/icon-classes";

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: keyof typeof iconClassNames;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger"
    | "outline-warning"
    | "outline-info"
    | "outline-light"
    | "outline-dark";
  size?: "sm" | "md" | "lg";
  children?: undefined;
}

const sizeStyles = {
  sm: { btn: { width: "2rem", height: "2rem" }, icon: { fontSize: "1rem" } },
  md: { btn: { width: "2.5rem", height: "2.5rem" }, icon: { fontSize: "1.3rem" } },
  lg: { btn: { width: "3rem", height: "3rem" }, icon: { fontSize: "1.6rem" } },
};

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { icon, variant, size = "md", style, className, ...rest } = props;

  return (
    <button
      type="button"
      style={{
        ...style,
        ...sizeStyles[size].btn,
      }}
      className={`p-0 d-flex justify-content-center align-items-center btn btn-${variant} ${className}`}
      {...rest}
    >
      <i className={`${icon} text-white`} style={sizeStyles[size].icon} />
    </button>
  );
};

export default IconButton;
