import React from "react";

interface FormSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  type?: string;
  label?: string;
  required?: boolean;
  error?: string;
  data: { label: string; value: string | number }[];
  correctValueMessage?: string;
  inputClassName?: string;
  labelClassName?: string;
}

const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  (
    {
      placeholder,
      type = "text",
      label,
      required,
      error,
      correctValueMessage,
      inputClassName,
      labelClassName,
      data,
      value,
      onChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <>
        {label && <label className={labelClassName}>{label}</label>}
        <select
          className={`form-control ${error ? "is-invalid" : ""} ${inputClassName ? inputClassName : ""}`}
          value={value}
          required={required}
          ref={ref}
          onChange={onChange}
          {...rest}
        >
          {placeholder && (
            <option disabled selected value="">
              {placeholder}
            </option>
          )}
          {data.map((item, i) => (
            <option key={`${item.value} - ${i}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
        {correctValueMessage && <div className="valid-feedback">{correctValueMessage}</div>}
      </>
    );
  },
);

export default FormSelect;
