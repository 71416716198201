import { ReactElement, useRef } from "react";
import { AccordionItemProps } from "./AccordionItem";
import "./Accordion.scss";

type AccordionProps = {
  children: ReactElement<AccordionItemProps> | Array<ReactElement<AccordionItemProps>>;
  originalStyles?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({ children, originalStyles }: AccordionProps) => {
  const accordionId = useRef(Math.random().toString(36).substring(7));

  return (
    <div className={`accordion accordion-flush ${originalStyles ? "" : "strip-styles"}`} id={accordionId.current}>
      {children}
    </div>
  );
};

export default Accordion;
