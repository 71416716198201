import { RouteItemConfig } from "../models/RouteItemConfig";
import LoginView from "../../views/account/login/loginView";
import RegisterView from "../../views/account/register/registerView";
import SetPasswordView from "views/account/setPassword/setPasswordView";

export const AccountRoutes: RouteItemConfig[] = [
  {
    path: "/account/login",
    name: "Login",
    component: LoginView,
    layout: "/account",
    default: true,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/account/register",
    name: "Register",
    component: RegisterView,
    layout: "/account",
    default: true,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/account/setPassword",
    name: "SetPassword",
    component: SetPasswordView,
    layout: "/account",
    default: true,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
