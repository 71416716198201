import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TFunction } from "i18next";
import { ResetPasswordRequestType } from "services/accountService/models/AccountType";

export type ResetPasswordFormType = ResetPasswordRequestType & {
  confirmPassword: string;
};

export const getSetPasswordSchemaSchema = (t: TFunction<"SetPasswordView", undefined>) => {
  const schema = z
    .object({
      token: z.string().min(1, t("invalidValue")),
      email: z.string().email(t("invalidEmail")),
      password: z
        .string()
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, t("passwordComplexity")),
      confirmPassword: z.string().min(1, t("invalidValue")),
    })
    .refine(
      (data) => {
        return data.password === data.confirmPassword;
      },
      {
        path: ["confirmPassword"],
        message: t("passwordsNotMatch"),
      },
    ) as z.ZodType<ResetPasswordRequestType>;

  return zodResolver(schema);
};
