import i18next from "i18next";
import Cookies from "js-cookie";
import { TranslationModel } from "./models/translationModel";
import moment from "moment";
import LoginArView from "../../views/account/login/i18n/ar.json";
import LoginEnView from "../../views/account/login/i18n/en.json";
import RegisterEnView from "../../views/account/register/i18n/en.json";
import RegisterArView from "../../views/account/register/i18n/ar.json";
import SharedEnView from "../../views/home/shared/i18n/en.json";
import SharedArView from "../../views/home/shared/i18n/ar.json";
import SetPasswordEnView from "../../views/account/setPassword/i18n/en.json";
import SetPasswordArViewAr from "../../views/account/setPassword/i18n/ar.json";
import CommonAr from "./common/ar.json";
import CommonEn from "./common/en.json";
import ProgramsAr from "../../views/programs/i18n/ar.json";
import ProgramsEn from "../../views/programs/i18n/en.json";

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: ["ar", "en"],
    lng: "ar",
    resources: {
      ar: {
        LoginView: LoginArView,
        RegisterView: RegisterArView,
        Shared: SharedArView,
        SetPasswordView: SetPasswordArViewAr,
        Common: CommonAr,
        Programs: ProgramsAr,
      },
      en: {
        LoginView: LoginEnView,
        Shared: SharedEnView,
        RegisterView: RegisterEnView,
        SetPasswordView: SetPasswordEnView,
        Common: CommonEn,
        Programs: ProgramsEn,
      },
    },
  });
  const localizationStr = Cookies.get("localization");
  if (localizationStr) {
    const localization: TranslationModel = JSON.parse(localizationStr);
    i18next.changeLanguage(localization.CurrentLanguage);
    if (localization.isRTL) {
      document.body.classList.add("rtl");
      moment.locale("ar-sa");
    } else {
      document.body.classList.remove("rtl");
      moment.locale("en-au");
    }
    document.documentElement.lang = localization.CurrentLanguage;
  } else {
    const localization: TranslationModel = {
      CurrentLanguage: "ar",
      isRTL: true,
    };
    Cookies.set("localization", JSON.stringify(localization));

    i18next.changeLanguage(localization.CurrentLanguage);
    if (localization.isRTL) {
      document.body.classList.add("rtl");
      moment.locale("ar-sa");
    } else {
      document.body.classList.remove("rtl");
      moment.locale("en-au");
    }
  }
}

export const languageChange = (currentLanguage: string) => {
  const isRtl = currentLanguage === "ar";
  // const direction = currentLanguage === "ar" ? "rtl" : "";
  const locale: TranslationModel = {
    CurrentLanguage: currentLanguage,
    isRTL: isRtl,
  };
  localStorage.setItem("localization", JSON.stringify(locale));
  if (isRtl) document.body.classList.add("rtl");
  else document.body.classList.remove("rtl");
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
};
export const toArabicNumbers = (value: string) => {
  if (!value) {
    return "";
  }
  return value.replace(/\d/g, (d: any) => "٠١٢٣٤٥٦٧٨٩"[d]).replace(/\./g, "٫");
};
