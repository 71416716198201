type DropdownProps = {
  label: string;
  data: { value: string; id: string | number }[];
  noSelectionPlaceholder?: string;
  wrapperClassName?: string;
  value: string | number;
  onChange: (value: string) => void;
};

// TODO: Arrow icon coloring and alignment (padding)

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { label, data, noSelectionPlaceholder, wrapperClassName, value, onChange } = props;

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`${wrapperClassName}`} style={{ minWidth: 150 }}>
      <label className="mb-1">{label}</label>
      <select onChange={onLocalChange} value={value} className="form-select form-control" aria-label={label}>
        <option value="">{noSelectionPlaceholder ?? label}</option>
        {data.map((item) => (
          <option value={item.id} selected={`${value}` === `${item.id}`}>
            {item.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
