import { NotificationType } from "contexts/notification/NotificationContext";
import { useTranslation } from "react-i18next";

type ToastProps = {
  notification: NotificationType;
  onClose: (id?: number) => void;
};

const alertTypes: Record<NotificationType["type"], { class: string; icon: string }> = {
  success: { class: "success", icon: "icon-tick-square" },
  error: { class: "danger", icon: "icon-warning-circle" },
  warning: { class: "warning", icon: "icon-warning-circle" },
  notification: { class: "info", icon: "icon-warning-circle" },
};

const Toast = ({ notification, onClose }: ToastProps) => {
  const { description, type } = notification;
  const { t } = useTranslation("Common");
  return (
    <div className={`alert alert-${alertTypes[type].class} py-2 gap-2 d-flex justify-content-between min-w-200`}>
      <div className="d-flex gap-2">
        <i className={`${alertTypes[type].icon} fs-5`}></i>
        <span className="fw-bold">{t(type)} :</span>
        <div>{description}</div>
      </div>
      <i className="icon-close fs-5 self" onClick={() => onClose(notification.id)}></i>
    </div>
  );
};

export default Toast;
