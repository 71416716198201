import { useState, useCallback, ReactNode, createContext } from "react";

export type NotificationType = {
  id: number;
  description: string;
  type: "success" | "error" | "warning" | "notification";
};

interface NotificationContextType {
  notifications: NotificationType[];
  showNotification: (notification: Omit<NotificationType, "id">) => void;
  closeNotification: (id: number) => void;
}

const NOTIFICATION_TIMEOUT = 10000;

export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const showNotification = useCallback(({ description, type }: Omit<NotificationType, "id">) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, description, type }]);
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, NOTIFICATION_TIMEOUT);
  }, []);

  const closeNotification = useCallback((id: number) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, showNotification, closeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
