import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import FormInput from "components/blocks/FormInput";
import { useEffect, useState } from "react";
import {
  CountryCodeResultResponse,
  CountryResultResponse,
  GenderResultResponse,
} from "services/lookupService/models/LookupsType";
import { LookupService } from "services/lookupService";
import FormSelect from "components/blocks/FormSelect";
import { RefereeSignUpFormType } from "../register.schema";

type PersonalInfoProps = {
  getFormProps: UseFormRegister<RefereeSignUpFormType>;
  formErrors: FieldErrors<RefereeSignUpFormType>;
};

const PersonalInfo = ({ getFormProps, formErrors }: PersonalInfoProps) => {
  const { t, i18n } = useTranslation("RegisterView");
  const [countryCodesDdl, setCountryCodesDdl] = useState<CountryCodeResultResponse[]>([]);
  const [nationalityDdl, setNationalityDdl] = useState<CountryResultResponse[]>([]);
  const [genderDdl, setGenderDdl] = useState<GenderResultResponse[]>([]);

  const initializeAllDropDowns = async () => {
    const [countryCodeRes, nationalitiesRes, gendersRes] = await Promise.all([
      LookupService.getCountryCodes(),
      LookupService.getNationalities(),
      LookupService.getGenders(),
    ]);

    setCountryCodesDdl(countryCodeRes.data);
    setNationalityDdl(nationalitiesRes.data);
    setGenderDdl(gendersRes.data);
  };

  useEffect(() => {
    initializeAllDropDowns();
  }, []);

  return (
    <>
      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("nationalId")}
            inputClassName="form-control"
            placeholder={t("nationalId")}
            error={formErrors.nationalId?.message}
            {...getFormProps("nationalId")}
          />
        </div>
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("username")}
            inputClassName="form-control"
            placeholder={t("username")}
            error={formErrors.username?.message}
            {...getFormProps("username")}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("fullNameAr")}
            inputClassName="form-control"
            placeholder={t("fullNameAr")}
            error={formErrors.fullNameArabic?.message}
            {...getFormProps("fullNameArabic")}
          />
        </div>

        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("fullNameEn")}
            inputClassName="form-control"
            placeholder={t("fullNameEn")}
            error={formErrors.fullNameEnglish?.message}
            {...getFormProps("fullNameEnglish")}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="email"
            label={t("email")}
            inputClassName="form-control"
            placeholder={t("email")}
            error={formErrors.email?.message}
            {...getFormProps("email")}
          />
        </div>
        <div className="form-group my-2 col-6">
          <label>{t("phone")}</label>
          <div className="d-flex gap-1">
            {i18n.language !== "ar" && (
              <FormSelect
                placeholder={t("phone")}
                data={countryCodesDdl.map((item) => ({
                  label: `${item.countryCode} (${item.isoCode})`,
                  value: `${item.countryCode}`,
                }))}
                {...getFormProps("countryCode")}
                inputClassName={`form-select form-control w-25 ${formErrors.countryCode?.message ? "is-invalid" : ""}`}
              />
            )}

            <div className="w-75">
              <FormInput
                type="text"
                inputClassName={`form-control ${formErrors.phoneNumber?.message ? "is-invalid" : ""}`}
                placeholder={t("phone")}
                {...getFormProps("phoneNumber")}
              />
            </div>

            {i18n.language === "ar" && (
              <FormSelect
                data={countryCodesDdl.map((item) => ({
                  label: `${item.countryCode} (${item.isoCode})`,
                  value: `${item.countryCode}`,
                }))}
                {...getFormProps("countryCode")}
                inputClassName={`form-select form-control w-25 ${formErrors.countryCode?.message ? "is-invalid" : ""}`}
              />
            )}
          </div>
          <input
            type="hidden"
            className={formErrors.countryCode?.message || formErrors.phoneNumber?.message ? "is-invalid" : ""}
          />
          {(formErrors.countryCode?.message || formErrors.phoneNumber?.message) && (
            <div className="invalid-feedback">{formErrors.countryCode?.message || formErrors.phoneNumber?.message}</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group my-2 col-6">
          <FormSelect
            placeholder={t("nationality")}
            label={t("nationality")}
            data={nationalityDdl.map((item) => ({
              label: `${item.countryDetail.displayName} (${item.isoCode})`,
              value: `${item.countryDetail.displayName}`,
            }))}
            {...getFormProps("nationality")}
            error={formErrors.nationality?.message}
            inputClassName="form-select form-control"
          />
        </div>
        <div className="form-group my-2 col-6">
          <FormSelect
            placeholder={t("gender")}
            label={t("gender")}
            data={genderDdl.map((item) => ({
              label: item.genderDetail.displayName,
              value: item.id,
            }))}
            {...getFormProps("genderId", { valueAsNumber: true })}
            error={formErrors.genderId?.message}
            inputClassName="form-select form-control"
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
