import React from "react";
import { iconClassNames } from "../../assets/style/theme/icon-classes";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  type?: string;
  label?: string;
  required?: boolean;
  error?: string;
  correctValueMessage?: string;
  inputClassName?: string;
  labelClassName?: string;
  iconClassName?: keyof typeof iconClassNames;
  wrapperClassName?: string;
  leftElement?: React.ReactNode;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      placeholder,
      type = "text",
      label,
      required,
      error,
      correctValueMessage,
      inputClassName,
      labelClassName,
      iconClassName,
      wrapperClassName,
      leftElement,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={wrapperClassName}>
        {label && <label className={`mb-1 ${labelClassName}`}>{label}</label>}
        <div className={`position-relative ${iconClassName ? "input-with-icon" : ""}`}>
          <input
            type={type}
            className={`form-control ${error ? "is-invalid" : ""} ${inputClassName ? inputClassName : ""}`}
            placeholder={placeholder}
            required={required}
            ref={ref}
            {...rest}
          />
          {leftElement && (
            <div className="position-absolute start-0 top-50 translate-middle-y px-2 user-select-none">
              {leftElement}
            </div>
          )}
          {iconClassName && <i className={iconClassName} />}
        </div>
        {error && <div className="invalid-feedback">{error}</div>}
        {correctValueMessage && <div className="valid-feedback">{correctValueMessage}</div>}
      </div>
    );
  },
);

export default FormInput;
