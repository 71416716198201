import React from "react";
import "./RangeInput.scss";

type RangeInputProps = {
  leftElement?: React.ReactNode;
  label?: string;
  wrapperClassName?: string;
  placeholderStart?: string;
  placeholderEnd?: string;
};

const RangeInput: React.FC<RangeInputProps> = (props) => {
  const { leftElement, label, wrapperClassName, placeholderEnd, placeholderStart } = props;

  return (
    <div className={wrapperClassName}>
      {label && <label className="mb-1">{label}</label>}
      <div className="input-range form-control d-flex align-items-center gap-2">
        <input
          className="form-control clear-borders flex-grow-1"
          style={{ minWidth: 0 }}
          placeholder={placeholderStart}
        />
        <span className="fw-bold">-</span>
        <input
          className="form-control clear-borders flex-grow-1"
          style={{ minWidth: 0 }}
          placeholder={placeholderEnd}
        />
        {Boolean(leftElement) && <span className="px-2">{leftElement}</span>}
      </div>
    </div>
  );
};

export default RangeInput;
