import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { TranslationModel } from "../translation/models/translationModel";

import { AxiosRequestConfig } from "axios";

interface ApiClient extends Omit<AxiosInstance, "get" | "post" | "put" | "delete"> {
  get<R>(url: string, config?: AxiosRequestConfig): Promise<R>;
  post<R>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  put<R>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  delete<R>(url: string, config?: AxiosRequestConfig): Promise<R>;
}

axios.interceptors.response.use((response) => {
  return response.data;
});

axios.interceptors.request.use(async (config) => {
  const localStr: any = Cookies.get("localization");
  if (localStr) {
    const localization: TranslationModel = JSON.parse(localStr);
    config.headers.set("Accept-Language", localization.CurrentLanguage);
  } else {
    config.headers.set("Accept-Language", "ar");
  }

  config.headers.set("Content-Type", "application/json");

  return config;
});

export const HttpClient = {
  get: axios.get as ApiClient["get"],
  post: axios.post as ApiClient["post"],
  put: axios.put as ApiClient["put"],
  delete: axios.delete as ApiClient["delete"],
  axios: axios,
};
