import { useNotification } from "hooks/useNotification";
import Toast from "./Toast";
import "./ToastContainer.scss";
import { useTranslation } from "react-i18next";

const ToastContainer = () => {
  const { notifications, closeNotification } = useNotification();
  const {
    i18n: { language },
  } = useTranslation();

  const position = language === "ar" ? "bottom-left" : "bottom-right";

  return (
    <div className={`notification-container ${position}`}>
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          notification={notification}
          onClose={() => {
            closeNotification(notification.id);
          }}
        />
      ))}
    </div>
  );
};

export default ToastContainer;
