import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GridViewHeaderProps } from "../GridView.types";
import useGridStore from "../grid.store";
import { useDebounce } from "hooks/useDebounce";
import { useShallow } from "zustand/react/shallow";

const GridViewHeader: React.FC<GridViewHeaderProps> = (props) => {
  const { title, totalCount, addPageRoute, singularName } = props;

  const { t } = useTranslation("Common");
  const { setGeneralSearch, modelFilterVisible, toggleModelFilterVisible } = useGridStore(
    useShallow((state) => ({
      setGeneralSearch: state.setGeneralSearch,
      modelFilterVisible: state.modelFilterVisible,
      toggleModelFilterVisible: state.toggleModelFilterVisible,
    })),
  );
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setGeneralSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const onExcelExport = () => {
    alert("Excel Export");
  };

  return (
    <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
      <div>
        <h4 className="text-dark">{title}</h4>
        <p className="text-body">{totalCount === 0 ? t("noData") : `${t("youHave")} ${totalCount} ${singularName}`}</p>
      </div>

      <div className="d-flex flex-wrap mb-3 gap-3">
        <Link to={addPageRoute} className="btn btn-primary p-10-32P">
          {t("add")} {singularName}
        </Link>
        <div className="input-with-icon">
          <input
            value={searchValue}
            onChange={handleSearchChange}
            type="text"
            className="form-control"
            placeholder={t("search")}
            aria-label="Search"
          />
          <i className="icon-search" />
        </div>
        <button
          type="button"
          className={`btn ${modelFilterVisible ? "btn-outline-primary active" : " btn-outline-dark"}`}
          onClick={toggleModelFilterVisible}
        >
          <i className="icon-filter" />
        </button>
        <button id="export-filter-btn" type="button" className="btn btn-outline-dark" onClick={onExcelExport}>
          <i className=" icon-export" />
        </button>
      </div>
    </div>
  );
};

export default GridViewHeader;
