type DatePickerProps = {
  label: string;
  placeholder?: string;
  value: string;
  onChange: (date: Date) => void;
  wrapperClassName?: string;
};

// TODO: Gregorian & Hijri Date Picker
// TODO: Input direction (always ltr)
// TODO: Custom Icon and Coloring
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { label, placeholder, value, onChange, wrapperClassName } = props;

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    onChange(date);
  };

  return (
    <div className={`${wrapperClassName}`}>
      <label className="mb-1">{label}</label>
      <input
        type="date"
        className="form-control"
        onChange={onLocalChange}
        value={value}
        placeholder={placeholder}
        aria-label={placeholder ?? label}
      />
    </div>
  );
};

export default DatePicker;
