import { typedLocalStorage } from "helpers/typedLocalStorage";
import { SortDirection } from "services/shared-models/sharedEnums";
import { create } from "zustand";

type GridStoreType = {
  modelFilterVisible: boolean;
  toggleModelFilterVisible: () => void;
  modelFilterPined: boolean;
  toggleModelFilterPin: () => void;
  generalSearch: string;
  setGeneralSearch: (search: string) => void;
  sortState: Record<string, SortDirection>;
  toggleFieldSortState: (field: string, dir: SortDirection | null) => void;
  getSortRequestObj: () => { field: string; dir: SortDirection }[];
  pagination: {
    currentPage: number;
    totalPages: number;
  };
  setPagination: (pagination: { currentPage?: number; totalPages?: number }) => void;
};

const useGridStore = create<GridStoreType>((set, get) => ({
  modelFilterVisible: typedLocalStorage.getItem<boolean>("modelFilterPined") ?? false,
  toggleModelFilterVisible: () => {
    set({
      modelFilterVisible: !get().modelFilterVisible,
      modelFilterPined: false,
    });
    typedLocalStorage.setItem("modelFilterPined", false);
  },
  modelFilterPined: typedLocalStorage.getItem<boolean>("modelFilterPined") ?? false,
  toggleModelFilterPin: () => {
    const current = get().modelFilterPined;
    set({ modelFilterPined: !current });
    typedLocalStorage.setItem("modelFilterPined", !current);
  },
  generalSearch: "",
  setGeneralSearch: (search) => set({ generalSearch: search }),
  sortState: {},
  toggleFieldSortState: (field, dir) => {
    if (dir === null) {
      const newState = { ...get().sortState };
      delete newState[field];
      set({ sortState: newState });
    } else {
      set({ sortState: { ...get().sortState, [field]: dir } });
    }
  },
  getSortRequestObj: () => {
    const sortState = get().sortState;
    return Object.entries(sortState).map(([field, dir]) => ({ field, dir }));
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
  },
  setPagination: (pagination) =>
    set({
      pagination: {
        currentPage: pagination.currentPage ?? get().pagination.currentPage,
        totalPages: pagination.totalPages ?? get().pagination.totalPages,
      },
    }),
}));

export default useGridStore;
