import AccountLayout from "../../layouts/account/accountLayout";
import HomeLayout from "../../layouts/home/homeLayout";
import { RouteItemConfig } from "../models";
import { AccountRoutes } from "../accountRoutes/accountRoutes";
import { HomeRoutes } from "../homeRoutes/homeRoutes";

export const AppRoutes: RouteItemConfig[] = [
  {
    path: "/account",
    name: "Account",
    component: AccountLayout,
    layout: "",
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
    subMenu: [...AccountRoutes],
  },

  {
    path: "/home",
    name: "Home",
    component: HomeLayout,
    layout: "",
    default: false,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    subMenu: [...HomeRoutes],
  },
];
