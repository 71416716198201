import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TFunction } from "i18next";
import { RefereeSignUpRequestType } from "services/accountService/models/AccountType";

export type RefereeSignUpFormType = RefereeSignUpRequestType & {
  countryCode: string;
};

export const getRegisterSchema = (t: TFunction<"RegisterView", undefined>) => {
  const profileSchema = getProfileSchema(t) as any;
  const fullSchema = profileSchema.extend({
    jobId: z.number().min(1, t("invalidValue")),
    specialization: z.string().min(1, t("invalidValue")),
    university: z.string().min(1, t("invalidValue")),
    degreeId: z.number().min(1, t("invalidValue")),
  }) as z.ZodType<RefereeSignUpFormType>;

  return zodResolver(fullSchema);
};

export const getProfileSchema = (t: TFunction<"ProfileView", undefined>) => {
  return z.object({
    nationality: z.string().min(1, t("invalidValue")),
    username: z.string().min(1, t("invalidValue")),
    email: z.string().email(t("invalidValue")),
    phoneNumber: z.string().min(9, t("invalidValue")),
    fullNameArabic: z.string().min(1, t("invalidValue")),
    fullNameEnglish: z.string().min(1, t("invalidValue")),
    nationalId: z.string().min(10, t("invalidValue")),
    genderId: z.number().min(1, t("invalidValue")),
    countryCode: z.string().min(1, t("invalidValue")),
  }) as z.ZodType<Omit<RefereeSignUpFormType, "jobId" | "specialization" | "university" | "degreeId">>;
};
