import LoadingOverlay from "components/blocks/LoadingOverlay";
import Grid from "./components/Grid";
import GridViewHeader from "./components/GridViewHeader";
import Pagination from "./components/Pagination";
import { GridViewProps } from "./GridView.types";
import GridFilter from "./components/GridFilter";
// import useGridStore from "./grid.store";

const GridView: React.FC<GridViewProps> = ({ gridProps, viewHeaderProps, loading }) => {
  // const gridStore = useGridStore();

  const filterableSchemaFields = gridProps.gridSchema.filter((schema) => schema.filterable);

  return (
    <>
      <GridViewHeader {...viewHeaderProps} />
      <GridFilter gridSchema={filterableSchemaFields} />
      <div className="position-relative">
        <LoadingOverlay visible={loading} />
        <Grid {...gridProps} />
      </div>
      <Pagination />
    </>
  );
};

export default GridView;
