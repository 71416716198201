import React, { createContext, ReactNode, useContext, useState } from "react";
import { MenuContextType } from "./models/menuContextType";
import { RouteItemConfig } from "../../routes/models";

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState<RouteItemConfig>();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <MenuContext.Provider value={{ isMenuOpen, selectedMenuItem, toggleMenu, setSelectedMenuItem }}>
      {children}
    </MenuContext.Provider>
  );
};

// Custom hook to use the CookieContext
export const useMenuContext = (): MenuContextType => {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error("useMenuContext must be used within a MenuProvider");
  }
  return context;
};
