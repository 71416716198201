import type { RefereeSignUpFormType } from "../register.schema";
import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import FormInput from "components/blocks/FormInput";
import { useEffect, useState } from "react";
import { LookupService } from "services/lookupService";
import { DegreeResultResponse, JobResultResponse } from "services/lookupService/models/LookupsType";
import FormSelect from "components/blocks/FormSelect";

type ProfessionalInfoProps = {
  getFormProps: UseFormRegister<RefereeSignUpFormType>;
  formErrors: FieldErrors<RefereeSignUpFormType>;
};

const ProfessionalInfo = ({ getFormProps, formErrors }: ProfessionalInfoProps) => {
  const { t } = useTranslation("RegisterView");

  const [JobTitleDdl, setJobTitleDdl] = useState<JobResultResponse[]>([]);
  const [degreeDdl, setDegreeDdl] = useState<DegreeResultResponse[]>([]);

  const initializeAllDropDowns = async () => {
    const [countryCodeRes, degreesRes] = await Promise.all([LookupService.getJobs(), LookupService.getDegrees()]);

    setJobTitleDdl(countryCodeRes.data);
    setDegreeDdl(degreesRes.data);
  };

  useEffect(() => {
    initializeAllDropDowns();
  }, []);

  return (
    <>
      <div className="row">
        <div className="form-group my-2 col-6">
          <FormSelect
            label={t("job")}
            placeholder={t("job")}
            data={JobTitleDdl.map((item) => ({
              label: item.jobDetail.displayName,
              value: item.id,
            }))}
            {...getFormProps("jobId", { valueAsNumber: true })}
            error={formErrors.jobId?.message}
            inputClassName="form-select form-control"
          />
        </div>

        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("university")}
            inputClassName="form-control"
            placeholder={t("university")}
            error={formErrors.university?.message}
            {...getFormProps("university")}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            placeholder={t("specialization")}
            label={t("specialization")}
            inputClassName="form-control"
            error={formErrors.specialization?.message}
            {...getFormProps("specialization")}
          />
        </div>

        <div className="form-group my-2 col-6">
          <FormSelect
            label={t("degree")}
            placeholder={t("degree")}
            data={degreeDdl.map((item) => ({
              label: item.degreeDetail.displayName,
              value: item.id,
            }))}
            {...getFormProps("degreeId", { valueAsNumber: true })}
            error={formErrors.degreeId?.message}
            inputClassName="form-select form-control"
          />
        </div>
      </div>
    </>
  );
};

export default ProfessionalInfo;
