import { useTranslation } from "react-i18next";
import "./FileInput.scss";

type FileInputProps = {
  label?: string;
  clickHereForLabel?: string;
  onChange: (files: FileList | null) => void;
};

const FileInput: React.FC<FileInputProps> = ({ label, onChange, clickHereForLabel }) => {
  const { t } = useTranslation("Common");

  const onLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.files);
  };

  return (
    <div className="mb-3 file-input-container">
      {label && (
        <label htmlFor="formFile" className="form-label">
          {label}
        </label>
      )}
      <div className="position-relative">
        <div className="file-drop-zone">
          <span>
            <strong className="text-primary px-2">{t("clickHere")}</strong>
            {clickHereForLabel}
          </span>
          <input className="file-input" type="file" onChange={onLocalChange} />
        </div>
      </div>
    </div>
  );
};

export default FileInput;
