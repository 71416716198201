import { useTranslation } from "react-i18next";

type FilePreviewProps = {
  file?: File;
  onDelete: (file: File) => void;
  onShow: (file: File) => void;
};

const FilePreview: React.FC<FilePreviewProps> = ({ file, onDelete, onShow }) => {
  const { t } = useTranslation("Common");

  if (!file) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-between border p-2 rounded-3 bg-body gap-4">
      <div className="d-flex align-items-center gap-2">
        <span className="icon-file fs-5 text-primary" />
        <span>{file?.name}</span>
        <span>•</span>
        <span className="text-primary fw-bold" role="button" onClick={() => onShow(file)}>
          {t("show")}
        </span>
      </div>
      <div className="d-flex align-items-center gap-2">
        <span style={{ fontSize: 14 }}>{(file.size / 1024 / 1024).toFixed(2)}MB</span>
        <span className="text-secondary fs-4 d-flex align-content-center" role="button" onClick={() => onDelete(file)}>
          <i className="icon-x" />
        </span>
      </div>
    </div>
  );
};

export default FilePreview;
