import IconButton from "components/blocks/IconButton";
import Switch from "components/blocks/Switch";
import { useTranslation } from "react-i18next";

import "./AddProgramView.scss";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import Dropdown from "components/blocks/Dropdown";
import { useNavigate } from "react-router-dom";
import DatePicker from "components/blocks/DatePicker";
import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import RangeInput from "components/blocks/RangeInput";

// TODO: Extract the building blocks into components (e.g. Page Header...)

const AddProgramView = () => {
  const { t } = useTranslation("Programs");
  const navigate = useNavigate();

  const onBackClicked = () => {
    navigate(-1);
  };

  return (
    <form className="add-program-page-wrapper">
      {/* Page Header */}
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton icon="icon-full-arrow-right" size="lg" className="text-dark" onClick={onBackClicked} />
          <div>
            <div className="d-flex gap-2">
              <h4 className="text-dark">إضافة برنامج</h4>
              <Switch
                checked
                onChange={(checked) => {
                  // TODO: on project publish switch toggled
                }}
              />
            </div>
            <p className="text-body m-0">رقم البرنامج: 15-2024</p>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <IconButton icon="icon-share" size="md" variant="light" className="text-dark" />
          <button className="btn btn-primary px-3">{t("Common:save")}</button>
        </div>
      </div>

      <div className="divider" />

      {/* Program Main Info */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("programMainInfo")} elementId={1}>
          <div className="d-flex gap-4 flex-wrap mb-3">
            <FormInput wrapperClassName="flex-grow-1" label={t("programNameAr")} />
            <FormInput wrapperClassName="flex-grow-5" label={t("ProgramDescAr")} />
          </div>
          <div className="d-flex gap-4 flex-wrap">
            <FormInput wrapperClassName="flex-grow-1" label={t("programNameEn")} />
            <FormInput wrapperClassName="flex-grow-5" label={t("ProgramDescEn")} />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* About Program */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("aboutTheProgram")} elementId={2}>
          <div className="row">
            <Dropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("programType")}
              data={[
                { id: 1, value: "النوع 1" },
                { id: 2, value: "النوع 2" },
              ]}
              onChange={() => {}}
              value={1}
            />
            <Dropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("programClassification")}
              data={[
                { id: 1, value: "التصنيف 1" },
                { id: 2, value: "التصنيف 2" },
              ]}
              onChange={() => {}}
              value={1}
            />
            <DatePicker
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("contractStart")}
              onChange={(date) => {}}
              value=""
            />
            <FormInput
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("fundingDuration")}
              maxLength={6}
              leftElement={<span className="text-primary">{t("months")}</span>}
            />
            <DatePicker
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("adsStart")}
              onChange={(date) => {}}
              value=""
            />
            <DatePicker
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("adsClose")}
              onChange={(date) => {}}
              value=""
            />
            <FormInput
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("fundingAmount")}
              leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
            />
            <Dropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("targetedAudience")}
              data={[
                { id: 1, value: "طلاب الدراسات العليا" },
                { id: 2, value: "طلاب الدكتوراة" },
              ]}
              onChange={() => {}}
              value={1}
            />
            <Dropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("cycleYear")}
              data={Array.from({ length: 5 }, (_, i) => ({
                id: i + 1,
                value: `${new Date().getFullYear() - 2 + i}`,
              }))}
              onChange={() => {}}
              value={1}
            />
            <Dropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("cycle")}
              data={[
                { id: 1, value: "Cycle 1" },
                { id: 2, value: "Cycle 2" },
              ]}
              onChange={() => {}}
              value={1}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Program Conditions / Rules */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("rules")} elementId={3}>
          <Dropdown
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100"
            label={t("cycle")}
            data={[
              { id: 1, value: "دورة 1" },
              { id: 2, value: "دورة 2" },
            ]}
            onChange={() => {}}
            value={1}
          />
          <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "TODO: customize for template")} />

          <Dropdown
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100"
            label={t("commitment")}
            data={[
              { id: 1, value: "التزام 1" },
              { id: 2, value: "التزام 2" },
            ]}
            onChange={() => {}}
            value={1}
          />
          <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "TODO: customize for template")} />
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Other */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("others")} elementId={3}>
          <FileInput
            label={t("Common:attachments")}
            onChange={(file) => {}}
            clickHereForLabel={t("Common:toAttachFile")}
          />
          <div className="d-flex gap-2">
            <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
            <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
          </div>

          <div className="divider" />

          <FileInput label={t("adImage")} onChange={(file) => {}} clickHereForLabel={t("toAttachAdImage")} />
          <div className="d-flex">
            <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
          </div>

          <div className="divider" />

          <Dropdown
            wrapperClassName="col-md-3 col-sm-6 w-100"
            label={t("contractForm")}
            data={[
              { id: 1, value: "نموذج 1" },
              { id: 2, value: "نموذج 2" },
            ]}
            onChange={() => {}}
            value={1}
          />
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Financing Calculation Terms */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("financingCalculationTerms")} elementId={4}>
          <div className="d-flex gap-4 align-items-end">
            <div className="d-flex gap-4 flex-grow-1">
              <Dropdown
                wrapperClassName="flex-grow-1"
                label={t("financingAmount")}
                onChange={() => {}}
                value={1}
                data={[{ id: 1, value: "Option 1" }]}
              />
              <Dropdown
                wrapperClassName="flex-grow-1"
                label={t("financingPercentage")}
                onChange={() => {}}
                value={1}
                data={[{ id: 1, value: "Option 1" }]}
              />
              <RangeInput
                wrapperClassName="flex-grow-1"
                label={t("financingPercentage")}
                leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
                placeholderStart={t("Common:from")}
                placeholderEnd={t("Common:to")}
              />
            </div>
            <IconButton size="lg" variant="danger" icon="icon-delete" />
          </div>
        </AccordionItem>
      </Accordion>
    </form>
  );
};

export default AddProgramView;
