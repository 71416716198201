import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResearchLogo from "assets/image/login/resarchlogo.png";
import Icon from "assets/image/login/icon.png";
import ProfessionalInfo from "./components/ProfessionalInfo";
import PersonalInfo from "./components/PersonalInfo";
import { RefereeSignUpFormType, getProfileSchema, getRegisterSchema } from "./register.schema";
import { useRef, useState } from "react";
import { RefereeSignUpRequestType } from "services/accountService/models/AccountType";
import { AccountService } from "services/accountService";
import { useNavigate } from "react-router-dom";

const RegisterView = () => {
  const { t } = useTranslation(["RegisterView", "Common"]);
  const navigate = useNavigate();
  const personalInfoSchema = useRef(getProfileSchema(t));

  const [isProfileInfoStepActive, setIsProfileInfoStepActive] = useState(true);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [formSubmissionStates, setFormSubmissionStates] = useState({
    isLoading: false,
    error: "",
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
    setError,
    clearErrors,
  } = useForm<RefereeSignUpFormType>({
    resolver: getRegisterSchema(t),
    values: {
      countryCode: "+966",
      nationalId: "",
      username: "",
      fullNameArabic: "",
      fullNameEnglish: "",
      email: "",
      phoneNumber: "",
      degreeId: 0,
      genderId: 1,
      jobId: 0,
      nationality: "",
      specialization: "",
      university: "",
    },
  });

  const onSubmit = async (data: RefereeSignUpFormType) => {
    setFormSubmissionStates({ error: "", isLoading: true });
    try {
      data.phoneNumber = data.countryCode + data.phoneNumber;
      const res = await AccountService.signUp(data);

      if (!res.hasError) {
        navigate("/account/login?isFromSignUp=true");
      } else {
        setFormSubmissionStates({
          ...formSubmissionStates,
          error: t(res.description),
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      setFormSubmissionStates({
        ...formSubmissionStates,
        error: t("Common:internalServerError"),
        isLoading: false,
      });
    }
  };

  const onStepChange = (step: "PersonalInfo" | "ProfessionalInfo") => {
    if (step === "ProfessionalInfo" && !validatePersonalInfoStep()) return;

    setIsProfileInfoStepActive(step === "PersonalInfo");
  };

  const onLoginHereClick = () => {
    navigate("/account/login");
  };

  const validatePersonalInfoStep = () => {
    const formValues = getValues();

    clearErrors();
    const res = personalInfoSchema.current.safeParse({
      email: formValues.email,
      phoneNumber: formValues.phoneNumber,
      fullNameArabic: formValues.fullNameArabic,
      fullNameEnglish: formValues.fullNameEnglish,
      nationalId: formValues.nationalId,
      genderId: formValues.genderId,
      username: formValues.username,
      nationality: formValues.nationality,
      countryCode: formValues.countryCode,
    });

    if (res.success) {
      setIsFormValidated(false); // Reset form validation after first step validation
      return true;
    }

    setIsFormValidated(true);
    res.error.errors.forEach((error) => {
      setError(error.path[0] as keyof RefereeSignUpRequestType, {
        type: "manual",
        message: error.message,
      });
    });

    return false;
  };

  return (
    <div className="container-fluid">
      <div className="row sing-up-container p-2">
        <div className="col-md-6  col-sm-12  d-flex justify-content-center h-100 ">
          <div className="sign-up-form   rounded">
            <div className=" text-center mb-3">
              <img src={Icon} width="92" height="147" alt="Logo" className="mb-3" />
            </div>
            <div>
              <div className="  d-flex flex-column align-items-center mb-4">
                <h2>{t("registerWelcome")}</h2>
                <p className="text-darkGray fs-14px">{t("registerDescription")}</p>
                <div className="d-flex gap-2 fs-12px">
                  <span
                    className={` step-circle cursor-pointer ${isProfileInfoStepActive ? "active" : "completed"}`}
                    onClick={(e) => onStepChange("PersonalInfo")}
                  ></span>
                  <span className="fw-bold cursor-pointer" onClick={(e) => onStepChange("PersonalInfo")}>
                    {t("personalInfo")}
                  </span>

                  <span className="icon-arrow-left-with-tile"></span>

                  <span className={` step-circle ${isProfileInfoStepActive ? "in-active" : "active"}`}></span>
                  <span className={`${isProfileInfoStepActive ? "text-gray" : "fw-bold"}`}>
                    {t("professionalInfo")}
                  </span>
                </div>
              </div>

              <form
                noValidate
                className={`${isFormValidated ? "was-validated" : ""}`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className={!isProfileInfoStepActive ? "d-none" : ""}>
                  <PersonalInfo getFormProps={register} formErrors={errors} />
                </div>
                <div className={isProfileInfoStepActive ? "d-none" : ""}>
                  <ProfessionalInfo getFormProps={register} formErrors={errors} />
                </div>
                {isProfileInfoStepActive ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      onStepChange("ProfessionalInfo");
                    }}
                    className="btn btn-dark btn-block w-100 my-3"
                  >
                    {t("next")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-dark btn-block w-100 my-3"
                    disabled={formSubmissionStates.isLoading}
                  >
                    {formSubmissionStates.isLoading ? <span className="custom-loader"></span> : t("sing-up")}
                  </button>
                )}
                <div className="text-center text-danger">{formSubmissionStates.error}</div>
              </form>
            </div>

            <p className="text-center">
              {t("loginMsg")}
              <button className="btn btn-link p-0 px-1 pt-2  text-decoration-none" onClick={onLoginHereClick}>
                {t("here")}
              </button>
            </p>
          </div>
        </div>

        <div className="col-md-6  col-sm-12  login-image ">
          <div className="overlay p-4  ">
            <div>
              <img src={ResearchLogo} width="181" height="88" alt="Logo" className="mb-3" />
            </div>

            <div>
              <h1>{t("universityName")}</h1>
              <p>{t("universityDescription")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterView;
